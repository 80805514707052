import React, { useState, useEffect, useLayoutEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useParams, useNavigate, useLocation } from 'react-router-dom';
import LatestPosts from './components/LatestPosts';
import PostContent from './components/PostContent';
import AllPosts from './components/AllPosts';
import { loadMarkdown } from './loadMarkdown';

const postsData = [
  { id: 1, title: 'The Impact and Future of ChatGPT', fileName: '23jul.md' },
  { id: 2, title: 'Symbolic - Album Review', fileName: '24jul.md' },
  { id: 3, title: 'My Top 5 Albums of 2024 Q1', fileName: '25jul.md' },
];

function PostWrapper({ posts }) {
  const { id } = useParams();
  const navigate = useNavigate();
  const post = posts && posts.length > 0 ? posts.find(p => p.id === parseInt(id)) : null;
  
  if (!posts || posts.length === 0) {
    return <div>Loading...</div>;
  }
  
  return (
    <div>
      <button onClick={() => navigate(-1)} style={{ margin: '20px' }}>
        ← Back
      </button>
      <PostContent post={post || posts[0]} />
    </div>
  );
}

function AppContent() {
  const [posts, setPosts] = useState([]);
  const [selectedPost, setSelectedPost] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const loadPosts = async () => {
      const loadedPosts = await Promise.all(
        postsData.map(async (post) => {
          const content = await loadMarkdown(post.fileName);
          return { ...post, content };
        })
      );
      setPosts(loadedPosts);
      setSelectedPost(loadedPosts[loadedPosts.length-1]);
    };

    loadPosts();
  }, []);

  const handlePostClick = async (post) => {
    const content = await loadMarkdown(post.fileName);
    setSelectedPost({ ...post, content });
    window.location.href = `/post/${post.id}`;
  };

  return (
    <div className="App">
      <Routes>
        <Route path="/post/:id" element={<PostWrapper posts={posts} />} />
        <Route path="*" element={
          <>
            <div id="heading-container">
              <h1><strong><span id="welcome">Welcome to Aryamaan's Blog!</span></strong></h1>
            </div>
            <h2>My Latest Posts</h2>
            {location.pathname === "/" && (
              <>
                <LatestPosts posts={posts} onPostClick={handlePostClick} />
                {selectedPost && <PostContent post={selectedPost} />}
              </>
            )}
            {location.pathname === "/all-posts" && (
              <>
                <button onClick={() => navigate('/')} style={{ margin: '20px' }}>
                  ← Back 
                </button>
                <AllPosts posts={[...posts].reverse()} onPostClick={handlePostClick} />
              </>
            )}
          </>
        } />
      </Routes>
    </div>
  );
}

function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

export default App;
